import React, { useState } from "react";
import "./Header.css"; // Import SCSS for Button
import { Link } from "react-router-dom";
import ButtonLink from "../ButtonLink";
import { FaUserCircle } from "react-icons/fa";

// interface Menu {
//   text: string;
//   type: string;
//   link: string;
//   class?: string;
//   menus?: Array<any>;
// }
// interface HeaderProps {
//   menu: Array<Menu>;
// }

const Header = ({ menu }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Dashboard");

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const renderIcon = (menu) => {
    return menu.icon === "userCircle" ? (
      <>
        <FaUserCircle className="_header_icon" /> {menu.text}
      </>
    ) : (
      menu.text
    );
  };
  return (
    <header className="header">
      <div className="logo">
        <img src="/logo.png" alt="Logo" />
      </div>
      <nav className={`nav header-nav ${isMenuOpen ? "open" : ""}`}>
        <ul>
          {menu.map((item) => {
            switch (item.type) {
              case "button_Link":
                return (
                  <li key={item.text}>
                    <ButtonLink
                      text={item.text}
                      url={item.link}
                      className={item.class || ""}
                    ></ButtonLink>
                  </li>
                );
              case "text":
                return (
                  <li
                    key={item.text}
                    className={`${
                      selectedMenu === item.text ? "selected" : ""
                    }`}
                    onClick={() => setSelectedMenu(item.text)}
                  >
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                );
              case "dropdown":
                return (
                  <li
                    key={item.text}
                    className={`_dropdown_menu ${
                      selectedMenu === item.text ? "selected" : ""
                    }`}
                    onClick={() => setSelectedMenu(item.text)}
                  >
                    {item.icon ? renderIcon(item) : item.text}
                    <div className="header-dropdown">
                      <ul>
                        {item.menus &&
                          item.menus.map((subitem) => {
                            return (
                              <li key={subitem.link}>
                                <Link to={subitem.link}>{subitem.text}</Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </li>
                );

              default:
                break;
            }
          })}
        </ul>
      </nav>
      <button className="menu-toggle" onClick={toggleMenu}>
        {isMenuOpen ? "X" : "☰"}
      </button>
    </header>
  );
};

export default Header;
