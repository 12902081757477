/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./LessonPlanGenerator.css";
import {
  fetchGrades,
  fetchBoards,
  fetchSubjects,
  fetchChapters,
} from "../assesment_api";
import { generatelessonplan, updateLessonPlan } from "../api";
import Markdown from "react-markdown";
import CryptoJS from "crypto-js";
import LoadingAnimation from "../components/LoadingAnimation";

const encryptData = (data) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const generateSessionID = () => {
  return `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
};

const LessonPlanGenerator = () => {
  const [formData, setFormData] = useState({
    activityName: "",
    subject: "",
    grade: "",
    board: "",
    startDate: "",
    endDate: "",
    selectedChapter: "",
    hoursPerDay: "",
    description: "",
    sessionID: "",
    chatID: "",
  });

  // State for dropdown options
  const [grades, setGrades] = useState([]);
  const [boards, setBoards] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);

  const [sessionID] = useState(() => localStorage.getItem("sessionID")) || [];
  const sessionIdRef = useRef(
    localStorage.getItem("lpgenID")
      ? decryptData(localStorage.getItem("lpgenID"))
      : generateSessionID()
  );
  const newSessionID = useRef("");

  const [isLoading, setIsLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState(() => {
    const encryptedMessages = localStorage.getItem("chatMessages");
    return encryptedMessages ? decryptData(encryptedMessages) : [];
  });
  const [inputMessage, setInputMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [enableButtons, setEnableButtons] = useState(false);
  const [enableChatInput, setEnableChatInput] = useState(false);
  const [dateErrors, setDateErrors] = useState({
    startDate: false,
    endDate: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const chatEndRef = useRef(null);
  const formRef = useRef(null);

  // Fetch grades on component mount
  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("lpgenID");
      localStorage.removeItem("chatMessages");
    };

    setIsLoading(true);
    fetchGrades()
      .then((gradesData) => {
        setGrades(
          gradesData.map((grade) => ({
            classid: grade.id,
            name: `Grade ${grade.name}`,
          }))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch grades:", error);
        setIsLoading(false);
      });

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // Fetch boards when grade is selected
  useEffect(() => {
    if (formData.grade) {
      setIsLoading(true);
      fetchBoards(formData.grade)
        .then((boardsData) => {
          setBoards(
            boardsData.map((board) => ({
              id: board.id,
              name: board.name,
            }))
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch boards:", error);
          setIsLoading(false);
        });
    } else {
      setBoards([]);
    }
  }, [formData.grade]);

  // Fetch subjects when board is selected
  useEffect(() => {
    if (formData.grade && formData.board) {
      setIsLoading(true);
      fetchSubjects(formData.grade, formData.board)
        .then((subjectsData) => {
          setSubjects(
            subjectsData.map((subject) => ({
              id: subject.id,
              name: subject.name,
            }))
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch subjects:", error);
          setIsLoading(false);
        });
    } else {
      setSubjects([]);
    }
  }, [formData.grade, formData.board]);

  // Fix for chapters useEffect
  useEffect(() => {
    if (formData.grade && formData.board && formData.subject) {
      setIsLoading(true);
      fetchChapters(formData.grade, formData.board, formData.subject)
        .then((chaptersData) => {
          setChapters(
            chaptersData.map((chapter) => ({
              chaptername: chapter.name,
              chapternumber: chapter.number,
            }))
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch chapters:", error);
          setIsLoading(false);
        });
    } else {
      setChapters([]);
    }
  }, [formData.grade, formData.board, formData.subject]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.style.opacity = "1";
    }
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const newData = { ...prev, [name]: value };

      // Reset dependent fields when parent field changes
      if (name === "grade") {
        newData.board = "";
        newData.subject = "";
        newData.selectedChapter = "";
      } else if (name === "board") {
        newData.subject = "";
        newData.selectedChapter = "";
      } else if (name === "subject") {
        newData.selectedChapter = "";
      }

      return newData;
    });

    if (name === "startDate" || name === "endDate") {
      validateDates(formData.startDate, formData.endDate, name, value);
    }
  };

  const validateDates = (start, end, changedField, newValue) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const newStartDate =
      changedField === "startDate" ? new Date(newValue) : new Date(start);
    const newEndDate =
      changedField === "endDate" ? new Date(newValue) : new Date(end);

    let errors = { startDate: false, endDate: false };
    let message = "";

    if (newStartDate < today) {
      errors.startDate = true;
      message = "Start date cannot be in the past.";
    }
    if (newEndDate < today) {
      errors.endDate = true;
      message = "End date cannot be in the past.";
    }
    if (newStartDate > newEndDate) {
      errors.startDate = true;
      errors.endDate = true;
      message = "End date cannot be before start date.";
    }

    setDateErrors(errors);
    setErrorMsg(message);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowForm(false);
    setShowChat(true);

    validateDates(formData.startDate, formData.endDate, "finalCheck", null);
    if (!dateErrors.startDate && !dateErrors.endDate) {
      newSessionID.current = generateSessionID();
      sessionIdRef.current = newSessionID.current;
      localStorage.setItem("lpgenID", encryptData(newSessionID.current));

      setIsLoading(true);
      const planDetails = {
        activityName: formData.activityName,
        grade: formData.grade,
        board: formData.board,
        subject: formData.subject,
        startDate: formData.startDate,
        endDate: formData.endDate,
        chapter: formData.selectedChapter,
        hoursPerDay: formData.hoursPerDay,
        sender: "Lesson_plan",
        sessionID: sessionID,
        description: formData.description,
        chatID: `lpgenID_${newSessionID.current}`,
      };

      generatelessonplan(planDetails)
        .then((response) => {
          const responseMessage =
            response.message ||
            `Lesson plan generated: ${
              response.details || "Details not available"
            }`;
          const newMessages = [{ text: responseMessage, isUser: false }];
          setChatMessages(newMessages);
          localStorage.setItem("chatMessages", encryptData(newMessages));
          setIsLoading(false);
          setEnableButtons(true);
        })
        .catch((error) => {
          console.error("Failed to generate lesson plan:", error);
          setIsLoading(false);
          setEnableButtons(false);
        });
    }

    document
      .querySelector("._lesson-plan-form-container")
      .classList.add("hidden");
    setTimeout(() => {
      document
        .querySelector("._lessson_chat-container")
        .classList.add("visible");
    }, 500);
  };

  const handleChatSubmit = () => {
    if (!inputMessage.trim()) return;

    setIsLoading(true);
    const newMessages = [...chatMessages, { text: inputMessage, isUser: true }];
    setChatMessages(newMessages);
    localStorage.setItem("chatMessages", encryptData(newMessages));

    const planDetails = {
      activityName: formData.activityName,
      grade: formData.grade,
      board: formData.board,
      subject: formData.subject,
      startDate: formData.startDate,
      endDate: formData.endDate,
      chapter: formData.selectedChapter,
      hoursPerDay: formData.hoursPerDay,
      sender: "Lesson_plan",
      sessionID: sessionID,
      description: inputMessage,
      chatID: `lpgenID_${newSessionID.current}`,
    };

    generatelessonplan(planDetails)
      .then((response) => {
        const responseMessage =
          response.message || "Clarification request submitted successfully.";
        const updatedMessages = [
          ...newMessages,
          { text: responseMessage, isUser: false },
        ];
        setChatMessages(updatedMessages);
        localStorage.setItem("chatMessages", encryptData(updatedMessages));
        setInputMessage("");
        setIsLoading(false);
        setEnableButtons(true);
      })
      .catch((error) => {
        console.error("Failed to send clarification:", error);
        setIsLoading(false);
        setEnableButtons(false);
      });
  };

  const handleNeedClarification = () => {
    setEnableChatInput(true);
    setEnableButtons(false);
  };

  const handleConfirm = () => {
    setEnableChatInput(false);
    setEnableButtons(true);
    setIsLoading(true);

    const lastSystemMessage = chatMessages.filter((msg) => !msg.isUser).pop();
    const encryptedUserData = localStorage.getItem("userData");
    const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};

    const updateDetails = {
      activityName: formData.activityName,
      sessionID: sessionID,
      chatID: `lpgenID_${newSessionID.current}`,
      username: userDetails.username,
      grade: formData.grade,
      subject: formData.subject,
      sender: "Lesson_plan",
      board: formData.board,
      chapter: formData.selectedChapter,
      startDate: new Date(formData.startDate).toISOString(),
      endDate: new Date(formData.endDate).toISOString(),
      lessonplan: lastSystemMessage
        ? lastSystemMessage.text
        : "No last response available",
    };

    updateLessonPlan(updateDetails)
      .then((response) => {
        console.log("Update sent:", response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to update lesson plan:", error);
        setIsLoading(false);
      });
  };

  return (
    <div className={`advanced-lesson-plan-generator`}>
      {isLoading && <LoadingAnimation />}
      <div className="_main-content">
        {showForm && (
          <div className="_lesson-plan-form-container">
            <h2 className="form-title">Generate Your Lesson Plan</h2>
            <form onSubmit={handleSubmit} className="lesson-plan-form">
              <div className="_row full-width">
                <input
                  type="text"
                  className="lessonPlan_input"
                  name="activityName"
                  placeholder="Suggest A Valid Unique Name...."
                  value={formData.activityName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="_row">
                <div className="column">
                  <select
                    name="grade"
                    className="lessonPlan_input_select"
                    value={formData.grade}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Grade</option>
                    {grades.map((grade) => (
                      <option key={grade.classid} value={grade.classid}>
                        {grade.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                    disabled={!formData.board}
                  >
                    <option value="">Select Subject</option>
                    {subjects.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="column">
                  <select
                    name="board"
                    value={formData.board}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                    disabled={!formData.grade}
                  >
                    <option value="">Select Board</option>
                    {boards.map((board) => (
                      <option key={board.id} value={board.id}>
                        {board.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="selectedChapter"
                    value={formData.selectedChapter}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                    disabled={!formData.subject}
                  >
                    <option value="">Select Chapter</option>
                    {chapters.map((chapter, index) => (
                      <option
                        key={`${chapter.chaptername}-${index}`}
                        value={chapter.chaptername}
                      >
                        {`${chapter.chaptername} (${chapter.chapternumber})`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="_row">
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="date"
                  name="endDate"
                  valuetype="date"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="_row">
                <select
                  name="hoursPerDay"
                  value={formData.hoursPerDay}
                  onChange={handleInputChange}
                  required
                  className="lessonPlan_input_select"
                >
                  <option value="">Hours per Day</option>
                  {[...Array(10)].map((_, i) => (
                    <option key={i} value={(i + 1) * 0.5}>
                      {(i + 1) * 0.5} Hours
                    </option>
                  ))}
                </select>
              </div>
              <div className="_row">
                <textarea
                  name="description"
                  placeholder="Specific Topic"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="lessonPlan_input_textarea"
                ></textarea>
              </div>
              {errorMsg && <div className="error-message">{errorMsg}</div>}
              <button
                type="submit"
                className="generate-button"
                disabled={isLoading}
              >
                {isLoading ? "Generating..." : "Generate Lesson Plan"}
              </button>
            </form>
          </div>
        )}
        {showChat && (
          <div className="_lessson_chat-container">
            <div className="_chat-messages">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`_message_ ${msg.isUser ? "user" : "system"}`}
                >
                  <Markdown children={msg.text} />
                </div>
              ))}
              <div ref={chatEndRef} />
            </div>
            <div className="button-container">
              <button disabled={!enableButtons} onClick={handleConfirm}>
                Confirm
              </button>
              <button
                disabled={!enableButtons}
                onClick={handleNeedClarification}
              >
                Need Clarification
              </button>
            </div>
            <div className="chat-input">
              <input
                type="text"
                className={
                  enableChatInput
                    ? "input-glow lessonPlan_input"
                    : "lessonPlan_input"
                }
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type a message..."
                disabled={!enableChatInput}
              />
              <button
                onClick={handleChatSubmit}
                disabled={!enableChatInput || !inputMessage.trim()}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonPlanGenerator;
