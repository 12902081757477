// src/components/PDFViewer/PDFViewer.jsx
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "../../pages/Study.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;

const PDFViewer = ({ url, width = "100%", onContextMenuSelect }) => {
  const [pdfReaderWidth, setPdfReaderWidth] = useState("100%");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(0.5);
  const [pdfError, setPdfError] = useState(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    selectedText: "",
  });

  const pdfContainerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfError(null);
  };

  const onDocumentLoadError = (error) => {
    console.error("Error loading PDF:", error);
    setPdfError("Failed to load the PDF. Please try again or contact support.");
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) =>
      Math.max(1, Math.min(prevPageNumber + offset, numPages))
    );
  };

  const handleZoom = (delta) => {
    setScale((prevScale) => Math.max(0.5, Math.min(prevScale + delta, 2.0)));
  };

  // Modified text selection handler
  const handleTextSelection = useCallback((e) => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (selectedText && pdfContainerRef.current) {
      e.preventDefault();
      e.stopPropagation();

      // Get container position
      const containerRect = pdfContainerRef.current.getBoundingClientRect();

      // Calculate menu position
      const x = e.clientX - containerRect.left;
      const y = e.clientY - containerRect.top;

      console.log("Selected Text:", selectedText); // Debug log
      console.log("Menu Position:", { x, y }); // Debug log

      setContextMenu({
        visible: true,
        x,
        y,
        selectedText,
      });
    }
  }, []);

  const handleContextMenuAction = (action) => {
    if (onContextMenuSelect) {
      onContextMenuSelect(action, contextMenu.selectedText);
    }
    setContextMenu({ visible: false, x: 0, y: 0, selectedText: "" });
  };

  const handleClickOutside = useCallback((e) => {
    if (
      !e.target.closest(".context-menu") &&
      !e.target.closest(".react-pdf__Page__textContent")
    ) {
      setContextMenu({ visible: false, x: 0, y: 0, selectedText: "" });
    }
  }, []);

  useEffect(() => {
    const pdfContainer = pdfContainerRef.current;

    if (pdfContainer) {
      pdfContainer.addEventListener("mouseup", handleTextSelection);
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      if (pdfContainer) {
        pdfContainer.removeEventListener("mouseup", handleTextSelection);
        document.removeEventListener("click", handleClickOutside);
      }
    };
  }, [handleTextSelection, handleClickOutside]);

  return (
    <div
      className="study_pdf-reader"
      style={{ width: pdfReaderWidth, height: "-webkit-fill-available" }}
    >
      <h2 className="study_section-title">PDF Document Reader</h2>

      {pdfError ? (
        <p className="study_error-message">{pdfError}</p>
      ) : (
        <div
          ref={pdfContainerRef}
          className="study_pdf-container relative"
          onContextMenu={(e) => e.preventDefault()}
        >
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            className="study_pdf-document"
          >
            <Page
              pageNumber={pageNumber}
              scale={scale}
              className="study_pdf-page"
              renderTextLayer={true}
              renderAnnotationLayer={true}
            />
          </Document>

          {contextMenu.visible && (
            <div
              className="context-menu"
              style={{
                position: "absolute",
                top: `${contextMenu.y}px`,
                left: `${contextMenu.x}px`,
                zIndex: 9999,
              }}
            >
              <div className="context-menu-options">
                <button onClick={() => handleContextMenuAction("explain")}>
                  Explain
                </button>
                <button onClick={() => handleContextMenuAction("generateVideo")}>
                Generate Video 
                </button>
                <button onClick={() => handleContextMenuAction("assessment")}>
                  Assessment
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {numPages && (
        <p>
          Page {pageNumber} of {numPages}
        </p>
      )}

      <div className="study_pdf-controls">
        <button
          onClick={() => changePage(-1)}
          disabled={pageNumber <= 1}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 hover:bg-blue-600 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={() => changePage(1)}
          disabled={pageNumber >= numPages}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 hover:bg-blue-600 transition-colors"
        >
          Next
        </button>
        <button
          onClick={() => handleZoom(0.1)}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Zoom In
        </button>
        <button
          onClick={() => handleZoom(-0.1)}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Zoom Out
        </button>
      </div>
    </div>
  );
};

export default PDFViewer;
