// src/teachers_api.js

const API_URL = "https://aiala.troshi.in/api";

export const getStudents = async (username) => {
    try {
      const response = await fetch(
        `${API_URL}/teachers/getStudents`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ username })  // Include username in the body of the POST request
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch Students: ${response.statusText}`);
      }
      const text = await response.text(); // Use .text() to handle non-JSON responses gracefully
      return text ? JSON.parse(text) : {}; // Only parse if text is not empty
    } catch (error) {
      console.error("Error fetching Students:", error);
      throw error;
    }
  };

  export const getActiveStudents = async (username, grades) => {
    try {
      const response = await fetch(
        `${API_URL}/teachers/getActiveStudents`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ username, grade: grades })  // Ensure 'grades' is an array
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch Students: ${response.statusText}`);
      }
      const text = await response.text(); // Use .text() to handle non-JSON responses gracefully
      return text ? JSON.parse(text) : {}; // Only parse if text is not empty
    } catch (error) {
      console.error("Error fetching Students:", error);
      throw error;
    }
  };