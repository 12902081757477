import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Login component
const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    handleLogout();
  }, []);
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };
};

export default Logout;
