import React, { useEffect, useState } from "react";
import { X, ChevronDown } from "lucide-react";
import {
  fetchGrades,
  fetchBoards,
  fetchChapters,
  fetchSubjects,
  fetchSubTopic,
  CompleteSubTopic,
} from "../../assesment_api";
import Select from "react-select";
import "./UpdateSyllabus.css";
import Loading from "../LoadingAnimation";
import CustomButton from "../CustomButton";

const UpdateSyllabusModal = ({ isOpen, onClose }) => {
  const [topic, setTopic] = useState("");
  const [isloading, setLoading] = useState(false);
  const [grades, setGrades] = useState("");
  const [boards, setBoards] = useState("");
  const [subjects, setSubjects] = useState("");
  const [chapters, setChapters] = useState("");
  const [subTopics, setSubTopics] = useState("");
  const [selectedGrade, setSelectedGrade] = useState();
  const [selectedBoard, setSelectedBoard] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedChapter, setSelectedChapter] = useState();
  const [selectedSubTopic, setSelectedSubTopic] = useState();
  const [remarks, setRemarks] = useState();

  useEffect(() => {
    getGrades();
  }, []);
  const getOptions = (data) => {
    return data.map((e) => {
      return { value: e.id, label: e.name };
    });
  };
  const resetSelection = (e) => {
    if (e === "grade") {
      setSelectedGrade("");
    }
    if (e === "board" || e === "grade") {
      setSelectedBoard("");
    }
    if (e === "subject" || e === "board" || e === "grade") {
      setSelectedSubject("");
    }
    if (e === "chapter" || e === "subject" || e === "board" || e === "grade") {
      setSelectedChapter("");
    }
    setSelectedSubTopic("");
    setRemarks("");
  };
  const getGrades = async () => {
    setLoading(true);
    let data = await fetchGrades();
    setGrades(getOptions(data));
    resetSelection("grade");
    setLoading(false);
  };
  const getBoards = async (e) => {
    setLoading(true);
    let data = await fetchBoards(e.value);
    setBoards(getOptions(data));
    resetSelection("board");
    setLoading(false);
  };
  const getSubjects = async (e) => {
    setLoading(true);
    let data = await fetchSubjects(selectedGrade.value, e.value);
    setSubjects(getOptions(data));
    resetSelection("subject");
    setLoading(false);
  };
  const getChapters = async (e) => {
    setLoading(true);
    let data = await fetchChapters(
      selectedGrade.value,
      selectedBoard.value,
      e.value
    );
    setChapters(getOptions(data));
    resetSelection("chapter");
    setLoading(false);
  };
  const getSubTopics = async (e) => {
    setLoading(true);
    let data = await fetchSubTopic(
      selectedGrade.value,
      selectedBoard.value,
      selectedSubject.value,
      e.value
    );
    setSubTopics(getOptions(data));
    resetSelection("topic");
    setLoading(false);
  };

  const updateSyllabus = async () => {
    const res = await CompleteSubTopic(
      selectedGrade.value,
      selectedBoard.value,
      selectedSubject.value,
      selectedChapter.value,
      selectedSubTopic.label,
      remarks
    );
    resetSelection("grade");
  };

  if (!isOpen) return null;

  return (
    <div className="modal-wrapper">
      {isloading && <Loading />}
      <div className="modal-card">
        <div className="flex justify-end">
          <X onClick={onClose} className="w-4 h-4" />
        </div>
        <div className="update_syl_content">
          <div className="row">
            <div className="col-lg-6 col-sm-12 col-md-12 pb-3">
              <label className="update_syl_label"> Select Grade</label>
              <Select
                onChange={(e) => {
                  setSelectedGrade(e);
                  getBoards(e);
                }}
                value={selectedGrade}
                options={grades}
              />
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 pb-3">
              <label className="update_syl_label"> Select Board</label>
              <Select
                onChange={(e) => {
                  setSelectedBoard(e);
                  getSubjects(e);
                }}
                value={selectedBoard}
                options={boards}
                isDisabled={!selectedGrade}
              />
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 pb-3">
              <label className="update_syl_label"> Select Subject</label>
              <Select
                onChange={(e) => {
                  setSelectedSubject(e);
                  getChapters(e);
                }}
                value={selectedSubject}
                options={subjects}
                isDisabled={!selectedBoard}
              />
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 pb-3">
              <label className="update_syl_label"> Select Chapter</label>
              <Select
                onChange={(e) => {
                  setSelectedChapter(e);
                  getSubTopics(e);
                }}
                value={selectedChapter}
                options={chapters}
                isDisabled={!selectedSubject}
              />
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 pb-3">
              <label className="update_syl_label"> Select Topic</label>
              <Select
                onChange={(e) => {
                  setSelectedSubTopic(e);
                }}
                value={selectedSubTopic}
                options={subTopics}
                isDisabled={!selectedChapter}
              />
            </div>
            <div className="col-lg-12 col-sm-12 col-md-12 pb-3">
              <label className="update_syl_label"> Remarks</label>
              <textarea
                name="remarks"
                placeholder="Remarks..."
                value={remarks}
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
                className="lessonPlan_input_textarea"
              ></textarea>
            </div>
          </div>
          <div>
            <CustomButton
              text="update"
              className="custom_button"
              type="button"
              onClickfn={updateSyllabus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSyllabusModal;
