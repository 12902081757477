import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getStudentAssessment, submitAnswers } from "../../api";
import "./StudentAssessment.css";
import CryptoJS from "crypto-js";

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const AssessmentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (location.state && location.state.id) {
        setLoading(true); // Start loading
        try {
          const response = await getStudentAssessment(location.state.id);
          const parsedQuestions = parseQuestions(response);
          setQuestions(parsedQuestions);
          setLoading(false); // Stop loading after data is fetched
        } catch (error) {
          console.error("Error fetching assessment questions:", error);
          alert("Error fetching assessment questions. Please try again.");
          setLoading(false); // Stop loading in case of an error
        }
      } else {
        console.warn("No assessment ID passed via state");
      }
    };

    fetchQuestions();
  }, [location.state]);

  const parseQuestions = (apiResponse) => {
    let questionId = 0;
    return apiResponse.exam_paper.sections
      .map((section) =>
        section.questions.map((question) => {
          questionId++;
          return {
            id: questionId,
            type: section.type,
            ...question,
          };
        })
      )
      .flat();
  };

  const handleAnswer = (questionId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));

    if (!answeredQuestions.includes(questionId)) {
      setAnsweredQuestions([...answeredQuestions, questionId]);
    }
  };

  const handleSubmit = async () => {
    const encryptedUserData = localStorage.getItem("userData");
    const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};
    try {
      setSubmitLoading(true);
      const response = await submitAnswers({
        answers,
        assessmentId: location.state.id,
        username: userDetails.username,
      });
      console.log("Submit response:", response);
      // alert("Assessment successfully submitted!");
      setSubmitLoading(true);
      navigate("/StudentReport", {
        state: { assessmentId: location.state.id },
      });
    } catch (error) {
      console.error("Error submitting answers:", error);
      alert("Failed to submit assessment. Please try again.");
      setSubmitLoading(false);
    }
  };

  const handleNavigation = (questionIndex) => {
    setCurrentQuestion(questionIndex);
  };

  const renderQuestion = (question) => {
    switch (question.type) {
      case "multipleChoice":
        return (
          <div className="question">
            <p>{question.question}</p>
            {question.options.map((option, index) => (
              <div key={index} className="option">
                <input
                  type="radio"
                  id={`option-${question.id}-${index}`}
                  name={`question-${question.id}`}
                  value={option}
                  checked={answers[question.id] === option}
                  onChange={(e) => handleAnswer(question.id, e.target.value)}
                />
                <label htmlFor={`option-${question.id}-${index}`}>
                  {option}
                </label>
              </div>
            ))}
          </div>
        );
      case "matchTheFollowing":
        return (
          <div className="question matching-question">
            <p className="topic">Topic: {question.topic}</p>
            <p>{question.question}</p>
            <div className="matching-container">
              {question.items.map((item, index) => (
                <div key={index} className="term-item">
                  <span className="term-label">{Object.keys(item)[0]}:</span>
                  <span className="term-content">{Object.values(item)[0]}</span>
                  <select
                    value={
                      (answers[question.id] &&
                        answers[question.id][Object.keys(item)[0]]) ||
                      ""
                    }
                    onChange={(e) =>
                      handleAnswer(question.id, {
                        ...answers[question.id],
                        [Object.keys(item)[0]]: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Match</option>
                    {question.matches.map((match, matchIndex) => (
                      <option key={matchIndex} value={Object.keys(match)[0]}>
                        {Object.keys(match)[0]}: {Object.values(match)[0]}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          </div>
        );
      case "fillInTheBlanks":
      case "textInput":
        return (
          <div className="question">
            <p>{question.question || question.sentence}</p>
            <textarea
              value={answers[question.id] || ""}
              onChange={(e) => handleAnswer(question.id, e.target.value)}
              rows="4"
            />
          </div>
        );
      default:
        return <div>Unknown question type: {question.type}</div>;
    }
  };
  return (
    <div className="assessment-page-container">
      <div className="assessment-content">
        <div className="question-area">
          {questions.length > 0 ? (
            <div className="question-container">
              <h2 className="question-number">
                Question {currentQuestion + 1}
              </h2>
              {renderQuestion(questions[currentQuestion])}
              <div className="button-group">
                {currentQuestion < questions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={() => setCurrentQuestion(currentQuestion + 1)}
                  >
                    Next
                  </button>
                ) : (
                  <button className="submit-button" onClick={handleSubmit}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div>No questions to display</div>
          )}
        </div>
        <div className="navigation-area">
          <h2 className="navigation-title">Questions</h2>
          <div className="question-navigation">
            {questions.map((q, index) => (
              <button
                key={q.id}
                className={`nav-button ${
                  currentQuestion === index ? "active" : ""
                } ${answeredQuestions.includes(q.id) ? "answered" : ""}`}
                onClick={() => handleNavigation(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
      {/* {loading && ( */}
      {(loading || submitLoading) && (
        <div className="loading-overlay-student">
          <div className="loading-message">
            {loading
              ? "Loading Your Examination... Get Ready!"
              : "Submitting Your Assessment..."}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentPage;
