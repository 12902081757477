// Study.js
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Upload, Mic, Play, Pause, Volume2, RefreshCw } from "lucide-react";
import "./Study.css";
import {
    getAllEducationalContent,
    getAllUserContent,
    getDocument,
    studentChatbot,
    uploadDocument,
    getAllUserDocumentURL,
    synthesizeSpeech
} from "../api";
import CryptoJS from "crypto-js";
import { IoSend } from "react-icons/io5";
import LoadingAnimation from "../components/LoadingAnimation";
import PDFViewer from '../components/ui/PDFViewer';

// Utility functions
const encryptData = (data) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        console.error("Decryption failed:", error);
        return null;
    }
};

const generateSessionId = () =>
    `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;

const generateSessionName = (lastMessage) => {
    return lastMessage
        ? `${lastMessage.substring(0, 20)}${lastMessage.length > 20 ? "..." : ""}`
        : "New Chat Session";
};

const Study = () => {
    // Form and UI States
    const [formData, setFormData] = useState({
        subject: "",
        grade: "",
        board: "",
        selectedChapter: "",
    });
    const [UserformData, setUserformData] = useState({ filename: "" });
    const [doctype, setDoctype] = useState("");
    const [currentForm, setCurrentForm] = useState("doctype");
    const [educationalContent, setEducationalContent] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [popupMessage, setPopupMessage] = useState("");
    const [userContent, setUserContent] = useState({});
    const [pdfReaderWidth, setPdfReaderWidth] = useState("100%");

    // Speech and Audio States
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [pitch, setPitch] = useState(0);
    const [rate, setRate] = useState(1);
    const [isListening, setIsListening] = useState(false);

    // Refs
    const audioRef = useRef(null);
    const recognitionRef = useRef(null);
    const pdfContainerRef = useRef(null);

    // Session and User States
    const sessionId = decryptData(localStorage.getItem("StudentchatSessionId")) || generateSessionId();
    const [sessionID] = useState(() => localStorage.getItem("sessionID")) || [];
    const [userDetails, setUserDetails] = useState({
        firstname: "",
        email: "",
        username: "",
    });
    const [selectedText, setSelectedText] = useState("");
    const [StudentsessionHistory, setStudentsessionHistory] = useState(
        () => decryptData(localStorage.getItem("StudentsessionHistory")) || []
    );
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // Message handling and storage functions
    const saveMessagesToLocalStorage = useCallback((sessionId, messages) => {
        localStorage.setItem(`Studentmessages_${sessionId}`, encryptData(messages));
    }, []);

    const loadMessagesFromLocalStorage = useCallback((sessionId) => {
        return decryptData(localStorage.getItem(`Studentmessages_${sessionId}`)) || [];
    }, []);


    const grades = useMemo(
        () =>
            Object.keys(educationalContent).map((key) => ({
                classid: key,
                name: `Grade ${key}`,
            })),
        [educationalContent]
    );

    const boards = useMemo(
        () =>
            formData.grade
                ? Object.keys(educationalContent[formData.grade]).map((key) => ({
                    id: key,
                    name: key,
                }))
                : [],
        [formData.grade, educationalContent]
    );

    const subjects = useMemo(
        () =>
            formData.grade && formData.board
                ? Object.keys(educationalContent[formData.grade][formData.board]).map(
                    (key) => ({ id: key, name: key })
                )
                : [],
        [formData.grade, formData.board, educationalContent]
    );

    const chapters = useMemo(
        () =>
            formData.grade && formData.board && formData.subject
                ? educationalContent[formData.grade][formData.board][formData.subject]
                : [],
        [formData.grade, formData.board, formData.subject, educationalContent]
    );
    // Speech recognition setup
    useEffect(() => {
        if ('webkitSpeechRecognition' in window) {
            const recognition = new window.webkitSpeechRecognition();
            recognition.continuous = false;
            recognition.interimResults = false;
            recognition.lang = 'en-US';

            recognition.onstart = () => setIsListening(true);
            recognition.onend = () => setIsListening(false);

            recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                setNewMessage(transcript);
                handleVoiceSendMessage(transcript);
            };

            recognition.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
                setIsListening(false);
            };

            recognitionRef.current = recognition;
        } else {
            console.error('Speech recognition not supported');
        }

        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.abort();
            }
        };
    }, []);

    // Speech synthesis function
    const handleSpeak = async (textToSpeak, language = 'en-US') => {
        try {
            setIsLoading(true);

            const speechParams = {
                text: textToSpeak,
                languageCode: language,
                gender: 'FEMALE',
                pitch,
                speakingRate: rate
            };

            const data = await synthesizeSpeech(speechParams);

            if (audioRef.current) {
                audioRef.current.pause();
            }

            const audio = new Audio(`data:audio/mp3;base64,${data.audioContent}`);
            audioRef.current = audio;
            audio.volume = volume;

            audio.onplay = () => setIsPlaying(true);
            audio.onended = () => {
                setIsPlaying(false);
                setIsLoading(false);
            };
            audio.onerror = () => {
                console.error('Error playing audio');
                setIsPlaying(false);
                setIsLoading(false);
            };

            await audio.play();
        } catch (err) {
            console.error('Error generating speech:', err);
            setIsPlaying(false);
            setIsLoading(false);
        }
    };

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const createMessageData = (messageText) => {
        const storedUserData = decryptData(localStorage.getItem("userData"));
        const selectedChapterDetails = formData.selectedChapter &&
            chapters.find(chapter => chapter.chaptername === formData.selectedChapter);
    
        return {
            message: messageText,
            sender: "studybuddy",
            firstname: storedUserData?.userData?.firstname || "Anonymous",
            username: userDetails.username || "anonymous",
            email: storedUserData?.userData?.email || "no-email@example.com",
            sessionID: sessionID,
            chatID: `Studentmessages_${sessionId}`,
            // Include form data with proper validation
            subject: formData.subject || "",
            class: formData.grade || "",
            board: formData.board || "",
            chapterName: selectedChapterDetails?.chaptername || "",
            chapterNumber: selectedChapterDetails?.chapternumber || "",
            usertype: storedUserData?.usertype || "student",
            timestamp: new Date().toISOString(),
        };
    };

    // Message handling function
    const handleVoiceSendMessage = async (transcript) => {
    if (!transcript.trim()) return;
    
    const messageData = createMessageData(transcript);
    console.log('Sending voice message data:', messageData); // For debugging

    setMessages(prevMessages => [
        ...prevMessages,
        { ...messageData, sender: "student" }
    ]);
    setNewMessage("");

    try {
        const responseData = await studentChatbot(messageData);
        if (responseData && responseData.message) {
            const botMessage = {
                message: responseData.message,
                sender: "bot",
                timestamp: new Date().toLocaleTimeString(),
            };
            setMessages(prevMessages => [...prevMessages, botMessage]);
            handleSpeak(responseData.message);
        }
    } catch (error) {
        console.error("Error sending message:", error);
        const errorMessage = {
            message: "Sorry, there was an error processing your request.",
            sender: "bot",
            timestamp: new Date().toLocaleTimeString(),
        };
        setMessages(prevMessages => [...prevMessages, errorMessage]);
        handleSpeak(errorMessage.message);
    }
};

const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    await handleVoiceSendMessage(newMessage);
};

    // Event handlers
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSendMessage();
            event.preventDefault();
        }
    };

    const handleDoctypeChange = (e) => {
        setDoctype(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleBackClick = () => {
        setCurrentForm("doctype");
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 2147483648) {
                alert("File size should not exceed 2GB.");
                return;
            }
            setUploadedFile(file);
        }
    };

    // Form submission handlers
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await getDocument(formData);
            if (response && response.url) {
                const pdfResponse = await fetch(response.url, {
                    method: "GET",
                    headers: { "Content-Type": "application/pdf" },
                });
                if (!pdfResponse.ok) throw new Error("Failed to fetch PDF");
                const pdfBlob = await pdfResponse.blob();
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfUrl(pdfUrl);
                setFormSubmitted(true);
            } else {
                throw new Error("Invalid document data received");
            }
        } catch (error) {
            console.error("Error fetching document:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit1 = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await getAllUserDocumentURL(UserformData);
            if (response && response.url) {
                const pdfResponse = await fetch(response.url, {
                    method: "GET",
                    headers: { "Content-Type": "application/pdf" },
                });
                if (!pdfResponse.ok) throw new Error("Failed to fetch PDF");
                const pdfBlob = await pdfResponse.blob();
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfUrl(pdfUrl);
                setFormSubmitted(true);
            } else {
                throw new Error("Invalid document data received");
            }
        } catch (error) {
            console.error("Error fetching document:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUploadSubmit = async (e) => {
        e.preventDefault();
        if (!uploadedFile) {
            alert("Please select a file to upload.");
            return;
        }
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("file", uploadedFile);
            const encryptedUserData = localStorage.getItem("userData");
            const userDetails = encryptedUserData
                ? decryptData(encryptedUserData)
                : {};
            formData.append("username", userDetails.username);
            const response = await uploadDocument(formData);
            if (response && response.message) {
                setPopupMessage(response.message);
                setTimeout(() => setPopupMessage(""), 5000);
            } else {
                throw new Error("Invalid document data received");
            }
        } catch (error) {
            console.error("Error uploading document:", error);
            setPopupMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // UI Components
    const renderChatMessage = (message) => (
        <div className={`chat-message ${message.sender}`}>
            <div className="message-content">
                {message.message}
                {message.sender === 'bot' && (
                    <button
                        className="speak-button"
                        onClick={() => handleSpeak(message.message)}
                        disabled={isPlaying || isLoading}
                    >
                        {isLoading ? (
                            <RefreshCw className="h-4 w-4 animate-spin" />
                        ) : isPlaying ? (
                            <Pause className="h-4 w-4" />
                        ) : (
                            <Play className="h-4 w-4" />
                        )}
                    </button>
                )}
            </div>
        </div>
    );

    const renderChatPopup = () => (
        <div className="chat-popup">
            <div className="chat-header">
                <h2>Study Buddy</h2>
                <div className="speech-controls">
                    <div className="volume-control">
                        <Volume2 className="h-4 w-4" />
                        <input
                            type="range"
                            min="0"
                            max="1"
                            step="0.1"
                            value={volume}
                            onChange={(e) => setVolume(parseFloat(e.target.value))}
                        />
                    </div>
                    <button
                        onClick={() => {
                            if (isListening) {
                                recognitionRef.current?.stop();
                            } else {
                                recognitionRef.current?.start();
                            }
                        }}
                        className={`mic-button ${isListening ? 'listening' : ''}`}
                    >
                        <Mic className="h-4 w-4" />
                    </button>
                </div>
            </div>
            <div className="chat-messages">
                {messages.map((msg, index) => renderChatMessage(msg, index))}
            </div>
            <div className="chat-input">
                <input
                    type="text"
                    placeholder="Type or speak a message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button onClick={handleSendMessage}>
                    <IoSend />
                </button>
            </div>
        </div>
    );
    const handleNextClick = async () => {
        setIsLoading(true);
        try {
            let response;
            if (doctype === "Preloaded") {
                response = await getAllEducationalContent();
                if (!response.ok) {
                    throw new Error(`Server responded with status: ${response.status}`);
                }
                const data = await response.json();
                setEducationalContent(JSON.parse(data));
            } else if (doctype === "Your Doc") {
                const storedUserData = decryptData(localStorage.getItem("userData"));
                response = await getAllUserContent(storedUserData.username);
                if (!response.ok) {
                    throw new Error(`Server responded with status: ${response.status}`);
                }
                const data = await response.json();
                setUserContent(data);
            }
            setCurrentForm(doctype === "Preloaded" ? "preloaded" : "yourDoc");
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setIsLoading(false);
    };
    const renderForm = () => {
        switch (currentForm) {
            case "doctype":
                return (
                    <div className="study_form-container">
                        <select
                            id="doctype"
                            onChange={handleDoctypeChange}
                            className="study_dropdown"
                        >
                            <option value="">Select Doctype</option>
                            <option value="Preloaded">Preloaded</option>
                            <option value="Your Doc">Your Doc</option>
                        </select>
                        <button onClick={handleNextClick} className="study_button">
                            Next
                        </button>
                    </div>
                );
            case "preloaded":
                return (
                    <form onSubmit={handleFormSubmit} className="study_form-container">
                        <select
                            name="grade"
                            className="study_dropdown"
                            value={formData.grade}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Grade</option>
                            {grades.map((grade) => (
                                <option key={grade.classid} value={grade.classid}>
                                    {grade.name}
                                </option>
                            ))}
                        </select>
                        <select
                            name="board"
                            value={formData.board}
                            onChange={handleInputChange}
                            required
                            className="study_dropdown"
                        >
                            <option value="">Select Board</option>
                            {boards.map((board) => (
                                <option key={board.id} value={board.id}>
                                    {board.name}
                                </option>
                            ))}
                        </select>
                        <select
                            name="subject"
                            value={formData.subject}
                            onChange={handleInputChange}
                            required
                            className="study_dropdown"
                        >
                            <option value="">Select Subject</option>
                            {subjects.map((subject) => (
                                <option key={subject.id} value={subject.id}>
                                    {subject.name}
                                </option>
                            ))}
                        </select>
                        <select
                            name="selectedChapter"
                            value={formData.selectedChapter}
                            onChange={handleInputChange}
                            required
                            className="study_dropdown"
                        >
                            <option value="">Select Chapter</option>
                            {chapters.map((chapter, index) => (
                                <option
                                    key={`${chapter.chaptername}-${index}`}
                                    value={chapter.chaptername}
                                >{`${chapter.chaptername} (${chapter.chapternumber})`}</option>
                            ))}
                        </select>
                        <button type="submit" className="study_button">
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={handleBackClick}
                            className="study_button study_button-back"
                        >
                            Back
                        </button>
                    </form>
                );
            case "yourDoc":
                return (
                    <form onSubmit={handleFormSubmit} className="study_form-container">
                        <div className="study_file-upload">
                            <input
                                type="file"
                                onChange={handleFileUpload}
                                accept=".pdf"
                                id="file-upload"
                                className="study_file-input"
                            />
                            <label htmlFor="file-upload" className="study_file-label">
                                <Upload size={24} />
                                <span>
                                    {uploadedFile ? uploadedFile.name : "Choose a file"}
                                </span>
                            </label>
                        </div>
                        <button
                            type="submit"
                            className="study_button"
                            onClick={handleUploadSubmit}
                        >
                            Upload and Submit
                        </button>
                        <select className="study_dropdown">
                            <option value="">Select Your Document</option>
                            {userContent.map((doc, index) => (
                                <option key={index} value={doc.filename}>
                                    {doc.filename}
                                </option>
                            ))}
                        </select>
                        <button
                            type="submit"
                            className="study_button"
                            onClick={handleFormSubmit1}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={handleBackClick}
                            className="study_button study_button-back"
                        >
                            Back
                        </button>
                    </form>
                );
            default:
                return null;
        }
    };

    // Additional initialization useEffects
    useEffect(() => {
        localStorage.setItem("StudentchatSessionId", encryptData(sessionId));
        const storedData = decryptData(localStorage.getItem("userData"));
        if (storedData) {
            setUserDetails({
                firstname: storedData.firstname,
                email: storedData.email,
                username: storedData.username,
            });
        }
    }, [sessionId]);

    useEffect(() => {
        const lastMessage = messages[messages.length - 1]?.message;
        const timestamp = new Date().toISOString();
        if (lastMessage && !StudentsessionHistory.find((s) => s.id === sessionId)) {
            const newSessionName = generateSessionName(lastMessage);
            const newHistory = [
                ...StudentsessionHistory,
                { id: sessionId, name: newSessionName, timestamp },
            ];
            setStudentsessionHistory(newHistory);
            localStorage.setItem("StudentsessionHistory", encryptData(newHistory));
        }
    }, [messages, StudentsessionHistory, sessionId]);

    useEffect(() => {
        setMessages(loadMessagesFromLocalStorage(sessionId));
    }, [sessionId, loadMessagesFromLocalStorage]);

    // Window resize handler
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Context menu handler
    const handleContextMenuSelect = (action, text) => {
        let message = '';
        switch (action) {
            case 'explain!!':
                message = `Can you explain this: "${text}"`;
                break;
            // case 'generateVideo':
            //     message = `Can you Generate video explanation for: "${text}"`;
            //     break;
            case 'Oral Q&A':
                message = `Can you create an assessment about: "${text}"`;
                break;
            default:
                return;
        }
        setNewMessage(message);
        setSelectedText("");
        handleSendMessage();
    };

    // Final return statement
    return (
        <div className="study_main-container">
            {isLoading && <LoadingAnimation />}
            {popupMessage && <div className="popup">{popupMessage}</div>}

            {!formSubmitted && (
                <div className="study_form-wrapper">{renderForm()}</div>
            )}

            {formSubmitted && (
                <div className="pdf_reader_wrap">
                    <PDFViewer
                        url={pdfUrl}
                        width={pdfReaderWidth}
                        onContextMenuSelect={handleContextMenuSelect}
                    />
                    <div style={{ width: "30%" }}>
                        {renderChatPopup()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Study;