import { useState, useEffect } from 'react';
import { fetchGrades, fetchBoards, fetchSubjects } from "../assesment_api";
import './DropdownFilters.css';

const DropdownFilters = ({ username }) => {
  const [grades, setGrades] = useState([]);
  const [boards, setBoards] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedBoard, setSelectedBoard] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Fetch grades on initial load
  useEffect(() => {
    const getGrades = async () => {
      setIsLoading(true);
      try {
        const gradesData = await fetchGrades(username);
        setGrades(gradesData);
      } catch (error) {
        console.error('Error fetching grades:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (username) {
      getGrades();
    }
  }, [username]);

  // Fetch boards when grade is selected
  useEffect(() => {
    const getBoards = async () => {
      if (!selectedGrade) {
        setBoards([]);
        setSelectedBoard('');
        return;
      }

      setIsLoading(true);
      try {
        const boardsData = await fetchBoards(selectedGrade);
        setBoards(boardsData);
      } catch (error) {
        console.error('Error fetching boards:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getBoards();
  }, [selectedGrade]);

  // Fetch subjects when board is selected
  useEffect(() => {
    const getSubjects = async () => {
      if (!selectedBoard || !selectedGrade) {
        setSubjects([]);
        setSelectedSubject('');
        return;
      }

      setIsLoading(true);
      try {
        const subjectsData = await fetchSubjects(selectedGrade, selectedBoard);
        setSubjects(subjectsData);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getSubjects();
  }, [selectedGrade, selectedBoard]);

  return (
    <div className="filters-container">
      <h2 className="filters-heading">Select Below to See Top and Needs Assistant Performers in this Week</h2>
      <div className="filters-grid">
        <div className="filter-group">
          <label htmlFor="grade" className="filter-label">
            Grade
          </label>
          <select
            id="grade"
            value={selectedGrade}
            onChange={(e) => setSelectedGrade(e.target.value)}
            className={`filter-select ${isLoading ? 'loading' : ''}`}
            disabled={isLoading}
          >
            <option value="">Select Grade</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.id}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-group">
          <label htmlFor="board" className="filter-label">
            Board
          </label>
          <select
            id="board"
            value={selectedBoard}
            onChange={(e) => setSelectedBoard(e.target.value)}
            className={`filter-select ${isLoading ? 'loading' : ''}`}
            disabled={!selectedGrade || isLoading}
          >
            <option value="">Select Board</option>
            {boards.map((board) => (
              <option key={board.id} value={board.id}>
                {board.name}
              </option>
            ))}
          </select>
        </div>

        <div className="filter-group">
          <label htmlFor="subject" className="filter-label">
            Subject
          </label>
          <select
            id="subject"
            value={selectedSubject}
            onChange={(e) => setSelectedSubject(e.target.value)}
            className={`filter-select ${isLoading ? 'loading' : ''}`}
            disabled={!selectedBoard || isLoading}
          >
            <option value="">Select Subject</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default DropdownFilters;