/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./ExpertActivityAssistant.css";
import {
  fetchGrades,
  fetchBoards,
  fetchSubjects,
  fetchChapters,
} from "../assesment_api";
import { generateActivity, updateActivity } from "../api";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CryptoJS from "crypto-js";
import CustomButton from "../components/CustomButton";
import LoadingAnimation from "../components/LoadingAnimation";
import {
  Alert,
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/AlertDialog";

const generateSessionID = () =>
  `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;

const ExpertActivityAssistant = () => {
  const encryptData = (data) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };
  const decryptData = (encryptedData) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };
  const navigate = useNavigate();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  // Form data and dropdown states
  const [formData, setFormData] = useState({
    activityName: "",
    subject: "",
    grade: "",
    board: "",
    selectedChapter: "",
    description: "",
    clarification: "",
    sessionID: "",
  });

  // State for dropdown options
  const [grades, setGrades] = useState([]);
  const [boards, setBoards] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);

  // Session management
  const sessionIdRef = useRef(
    localStorage.getItem("apgenID")
      ? decryptData(localStorage.getItem("apgenID"))
      : generateSessionID()
  );
  const newSessionID = useRef("");
  const [sessionID] = useState(() => localStorage.getItem("sessionID")) || [];

  // UI states
  const [isLoading, setIsLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState(() => {
    const encryptedMessages = localStorage.getItem("chatMessages");
    return encryptedMessages ? decryptData(encryptedMessages) : [];
  });
  const [inputMessage, setInputMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [showChat, setShowChat] = useState(false);
  const [enableButtons, setEnableButtons] = useState(false);
  const [enableChatInput, setEnableChatInput] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const chatEndRef = useRef(null);
  const formRef = useRef(null);

  // Fetch grades on component mount
  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.removeItem("apgenID");
      localStorage.removeItem("chatMessages");
    };

    setIsLoading(true);
    fetchGrades()
      .then((gradesData) => {
        setGrades(
          gradesData.map((grade) => ({
            classid: grade.id,
            name: `Grade ${grade.name}`,
          }))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch grades:", error);
        setIsLoading(false);
      });

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // Fetch boards when grade is selected
  useEffect(() => {
    if (formData.grade) {
      setIsLoading(true);
      fetchBoards(formData.grade)
        .then((boardsData) => {
          setBoards(
            boardsData.map((board) => ({
              id: board.id,
              name: board.name,
            }))
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch boards:", error);
          setIsLoading(false);
        });
    } else {
      setBoards([]);
    }
  }, [formData.grade]);

  // Fetch subjects when board is selected
  useEffect(() => {
    if (formData.grade && formData.board) {
      setIsLoading(true);
      fetchSubjects(formData.grade, formData.board)
        .then((subjectsData) => {
          setSubjects(
            subjectsData.map((subject) => ({
              id: subject.id,
              name: subject.name,
            }))
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch subjects:", error);
          setIsLoading(false);
        });
    } else {
      setSubjects([]);
    }
  }, [formData.grade, formData.board]);

  // Fetch chapters when subject is selected
  useEffect(() => {
    if (formData.grade && formData.board && formData.subject) {
      setIsLoading(true);
      fetchChapters(formData.grade, formData.board, formData.subject)
        .then((chaptersData) => {
          setChapters(
            chaptersData.map((chapter) => ({
              chaptername: chapter.name,
              chapternumber: chapter.number,
            }))
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch chapters:", error);
          setIsLoading(false);
        });
    } else {
      setChapters([]);
    }
  }, [formData.grade, formData.board, formData.subject]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.style.opacity = "1";
    }
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const newData = { ...prev, [name]: value };

      // Reset dependent fields when parent field changes
      if (name === "grade") {
        newData.board = "";
        newData.subject = "";
        newData.selectedChapter = "";
      } else if (name === "board") {
        newData.subject = "";
        newData.selectedChapter = "";
      } else if (name === "subject") {
        newData.selectedChapter = "";
      }

      return newData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowForm(false);
    setShowChat(true);

    document
      .querySelector(".lesson-plan-form-container")
      .classList.add("hidden");
    setTimeout(() => {
      document
        .querySelector("._lessson_chat-container")
        .classList.add("visible");
    }, 500);

    newSessionID.current = generateSessionID();
    sessionIdRef.current = newSessionID.current;
    localStorage.setItem("apgenID", encryptData(newSessionID.current));

    setIsLoading(true);
    const planDetails = {
      activityName: formData.activityName,
      grade: formData.grade,
      board: formData.board,
      subject: formData.subject,
      chapter: formData.selectedChapter,
      sender: "Activity_planner",
      sessionID: sessionID,
      description: formData.description,
      chatID: `apgenID_${newSessionID.current}`,
    };

    generateActivity(planDetails)
      .then((response) => {
        const responseMessage =
          response.message ||
          `Activity generated: ${response.details || "Details not available"}`;
        const newMessages = [
          ...chatMessages,
          { text: responseMessage, isUser: false },
        ];
        setChatMessages(newMessages);
        localStorage.setItem("chatMessages", encryptData(newMessages));
        setEnableButtons(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to generate Activity:", error);
        const newMessages = [
          ...chatMessages,
          {
            text: "Failed to generate Activity due to an error.",
            isUser: false,
          },
        ];
        setChatMessages(newMessages);
        localStorage.setItem("chatMessages", encryptData(newMessages));
        setEnableButtons(false);
        setIsLoading(false);
      });
  };

  const handleChatSubmit = () => {
    if (!inputMessage.trim()) return;

    setIsLoading(true);
    const newMessages = [...chatMessages, { text: inputMessage, isUser: true }];
    setChatMessages(newMessages);
    localStorage.setItem("chatMessages", encryptData(newMessages));

    const planDetails = {
      activityName: formData.activityName,
      grade: formData.grade,
      board: formData.board,
      subject: formData.subject,
      chapter: formData.selectedChapter,
      sender: "Activity_planner",
      sessionID: sessionID,
      description: inputMessage,
      chatID: `apgenID_${newSessionID.current}`,
    };

    generateActivity(planDetails)
      .then((response) => {
        const responseMessage =
          response.message || "Clarification request submitted successfully.";
        const updatedMessages = [
          ...newMessages,
          { text: responseMessage, isUser: false },
        ];
        setChatMessages(updatedMessages);
        localStorage.setItem("chatMessages", encryptData(updatedMessages));
        setInputMessage("");
        setIsLoading(false);
        setEnableButtons(true);
      })
      .catch((error) => {
        console.error("Failed to send clarification:", error);
        const errorMessages = [
          ...newMessages,
          {
            text: "Failed to send clarification due to an error.",
            isUser: false,
          },
        ];
        setChatMessages(errorMessages);
        localStorage.setItem("chatMessages", encryptData(errorMessages));
        setIsLoading(false);
        setEnableButtons(false);
      });
  };

  const handleNeedClarification = () => {
    setEnableChatInput(true);
    setEnableButtons(false);
  };

  const handleConfirm = () => {
    setEnableChatInput(false);
    setEnableButtons(true);
    setIsLoading(true);

    const lastSystemMessage = chatMessages.filter((msg) => !msg.isUser).pop();
    const encryptedUserData = localStorage.getItem("userData");
    const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};

    const updateDetails = {
      activityName: formData.activityName,
      sessionID: sessionID,
      chatID: `apgenID_${newSessionID.current}`,
      username: userDetails.username,
      grade: formData.grade,
      subject: formData.subject,
      board: formData.board,
      chapter: formData.selectedChapter,
      sender: "acitivity_gen",
      activity: lastSystemMessage
        ? lastSystemMessage.text
        : "No last response available",
    };

    updateActivity(updateDetails)
      .then((response) => {
        // console.log("Update sent:", response);
        setIsLoading(false);

        setShowSuccessDialog(true);
      })
      .catch((error) => {
        console.error("Failed to update Activity:", error);
        setIsLoading(false);
      });
  };

  const handleDialogClose = () => {
    setShowSuccessDialog(false);
    navigate("/lesson-plan"); // Replace with your actual lesson plan route
  };

  return (
    <div className={`advanced-lesson-plan-generator`}>
      {isLoading && <LoadingAnimation />}

      <div className="main-content">
        {showForm && (
          <div className="lesson-plan-form-container">
            <h2 className="form-title">Generate Your Activity</h2>
            <form onSubmit={handleSubmit} className="lesson-plan-form">
              <div className="_row full-width">
                <input
                  type="text"
                  className="lessonPlan_input"
                  name="activityName"
                  placeholder="Give A Name To your Activity"
                  value={formData.activityName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="_row">
                <div className="column">
                  <select
                    name="grade"
                    className="lessonPlan_input_select"
                    value={formData.grade}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Grade</option>
                    {grades.map((grade) => (
                      <option key={grade.classid} value={grade.classid}>
                        {grade.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                    disabled={!formData.board}
                  >
                    <option value="">Select Subject</option>
                    {subjects.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="column">
                  <select
                    name="board"
                    value={formData.board}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                    disabled={!formData.grade}
                  >
                    <option value="">Select Board</option>
                    {boards.map((board) => (
                      <option key={board.id} value={board.id}>
                        {board.name}
                      </option>
                    ))}
                  </select>
                  <select
                    name="selectedChapter"
                    value={formData.selectedChapter}
                    onChange={handleInputChange}
                    required
                    className="lessonPlan_input_select"
                    disabled={!formData.subject}
                  >
                    <option value="">Select Chapter</option>
                    {chapters.map((chapter, index) => (
                      <option
                        key={`${chapter.chaptername}-${index}`}
                        value={chapter.chaptername}
                      >
                        {`${chapter.chaptername} (${chapter.chapternumber})`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="_row">
                <textarea
                  name="description"
                  placeholder="Specific Topic"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="lessonPlan_input_textarea"
                ></textarea>
              </div>
              {errorMsg && <div className="error-message">{errorMsg}</div>}
              <CustomButton
                text={isLoading ? "Generating..." : "Generate Activity"}
                type="submit"
                isDisabled={isLoading}
                className="custom_button max btn_dark_bg"
              />
            </form>
          </div>
        )}
        {showChat && (
          <div className="_lessson_chat-container">
            <div className="chat-messages">
              {chatMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`message ${msg.isUser ? "user" : "system"}`}
                >
                  <Markdown
                    children={msg.text}
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                          <SyntaxHighlighter
                            style={dark}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          >
                            {String(children).replace(/\n$/, "")}
                          </SyntaxHighlighter>
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  />
                </div>
              ))}
              <div ref={chatEndRef} />
            </div>
            <div className="button-container">
              <button disabled={!enableButtons} onClick={handleConfirm}>
                Confirm
              </button>
              <button
                disabled={!enableButtons}
                onClick={handleNeedClarification}
              >
                Need Clarification
              </button>
            </div>

            <div className="chat-input">
              <input
                type="text"
                className={
                  enableChatInput
                    ? "input-glow lessonPlan_input"
                    : "lessonPlan_input"
                }
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type a message..."
                disabled={!enableChatInput}
              />
              <button
                onClick={handleChatSubmit}
                disabled={!enableChatInput || !inputMessage.trim()}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
      <AlertDialog open={showSuccessDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Success!</AlertDialogTitle>
            <AlertDialogDescription>
              Your Activity has been saved successfully.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={handleDialogClose}>
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ExpertActivityAssistant;
