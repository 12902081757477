// components/ui/alert-dialog/AlertDialog.js
import React from 'react';
import './AlertDialog.css';

export const AlertDialog = ({ open, children }) => {
  if (!open) return null;
  
  return (
    <div className="alert-dialog-overlay">
      <div className="alert-dialog-container">
        {children}
      </div>
    </div>
  );
};

export const AlertDialogContent = ({ children }) => {
  return (
    <div className="alert-dialog-content">
      {children}
    </div>
  );
};

export const AlertDialogHeader = ({ children }) => {
  return (
    <div className="alert-dialog-header">
      {children}
    </div>
  );
};

export const AlertDialogTitle = ({ children }) => {
  return (
    <h2 className="alert-dialog-title">
      {children}
    </h2>
  );
};

export const AlertDialogDescription = ({ children }) => {
  return (
    <p className="alert-dialog-description">
      {children}
    </p>
  );
};

export const AlertDialogFooter = ({ children }) => {
  return (
    <div className="alert-dialog-footer">
      {children}
    </div>
  );
};

export const AlertDialogAction = ({ onClick, children }) => {
  return (
    <button className="alert-dialog-action" onClick={onClick}>
      {children}
    </button>
  );
};

// Create an index.js file to export all components
export * from './AlertDialog';