import { React, useState, useEffect, useCallback } from "react";
import "./TeacherHome.css";
import { getStudents, getActiveStudents } from "../teachers_api";
import DropdownFilters from "./DropdownFilters";
import AddStudentModal from "../components/ui/AddStudent";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { ChevronLeft, ChevronRight, Pause, Play } from "lucide-react";
import UpdateSyllabusModal from "../components/ui/UpdateSyllabus";

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

// Constants
const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds
const CAROUSEL_INTERVAL = 5000; // 5 seconds per grade

const GradeCard = ({ data }) => (
  <div className="carousel-card">
    <div
      className="card-image"
      style={{
        background: `linear-gradient(45deg, ${getGradeColor(
          data.grade
        )}33, ${getGradeColor(data.grade)}66)`,
      }}
    />
    <div className="card-content">
      <h3 className="card-title">Grade {data.grade}</h3>
      <p className="card-description">
        Performance metrics and statistics for Grade {data.grade} students
      </p>
      <div className="metric-stats">
        <div className="metric-item">
          <div className="metric-value">{data.activeStudents}</div>
          <div className="metric-label">Active Students</div>
        </div>
        <div className="metric-item">
          <div className="metric-value">{data.totalAssessments}</div>
          <div className="metric-label">Assessments</div>
        </div>
        <div className="metric-item">
          <div className="metric-value">{data.averageScore}%</div>
          <div className="metric-label">Average Score</div>
        </div>
      </div>
    </div>
  </div>
);
const GradeKPI = ({ data }) => {
  console.log(data);
  return (
    <div>
      <div className="home_kpi_heading">
        <h6>Grade :{data.grade}</h6>
        <span>
          Performance metrics and statistics for Grade {data.grade} students
        </span>
      </div>
      <div className="home_kpi_cards">
        <div className="home_kpi_card">
          <div className="home_kpi_value">{data.activeStudents}</div>
          <div className="home_kpi_label">Active Students</div>
        </div>
        <div className="home_kpi_card">
          <div className="home_kpi_value">{data.totalAssessments}</div>
          <div className="home_kpi_label green">Assessments</div>
        </div>
        <div className="home_kpi_card">
          <div className="home_kpi_value">{data.averageScore}%</div>
          <div className="home_kpi_label pink">Average Score</div>
        </div>
      </div>
    </div>
  );
};
// Helper function to generate consistent colors for grades
const getGradeColor = (grade) => {
  const colors = [
    "#3b82f6",
    "#10b981",
    "#8b5cf6",
    "#ef4444",
    "#f59e0b",
    "#6366f1",
  ];
  return colors[grade % colors.length];
};

const Dashboard = () => {
  const [time] = useState(new Date());
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    firstname: "",
    username: "",
    grade: "",
    usertype: "",
  });
  const [students, setStudents] = useState([]);
  const [gradeDetails, setGradeDetails] = useState([]);
  const [currentGradeIndex, setCurrentGradeIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAutoRotating, setIsAutoRotating] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  // Encryption/Decryption utilities remain the same
  const encryptData = (data) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(
      JSON.stringify({
        data,
        timestamp: new Date().getTime(),
      }),
      secretKey
    ).toString();
  };

  const decryptData = (encryptedData) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };

  // Cache validation functions remain the same
  const isCacheValid = (timestamp) => {
    return new Date().getTime() - timestamp < CACHE_DURATION;
  };

  const getFromStorage = (key) => {
    const encryptedData = sessionStorage.getItem(key);
    if (!encryptedData) return null;

    const decryptedData = decryptData(encryptedData);
    if (!decryptedData || !isCacheValid(decryptedData.timestamp)) {
      sessionStorage.removeItem(key);
      return null;
    }

    return decryptedData.data;
  };

  // Fetch students with caching
  const fetchStudents = async (username) => {
    try {
      const cachedStudents = getFromStorage("studentsData");
      if (cachedStudents) {
        setStudents(cachedStudents.students);
        console.log(cachedStudents);
        return cachedStudents;
      }

      const response = await getStudents(username);
      if (response && response.students) {
        const encryptedStudentsData = encryptData(response);
        sessionStorage.setItem("studentsData", encryptedStudentsData);
        setStudents(response.students);
        return response;
      }
    } catch (error) {
      console.error("Error fetching students:", error);
      setError("Failed to fetch students data");
      return null;
    }
  };

  // Fetch assessment details with caching
  const fetchAssessmentDetails = async (username, grades) => {
    try {
      const cachedAssessments = getFromStorage("assessmentData");
      if (cachedAssessments) {
        setGradeDetails(cachedAssessments.gradeDetails);
        return;
      }

      const gradesList = Array.isArray(grades)
        ? grades
        : grades
            .replace(/[{}]/g, "")
            .split(",")
            .map((grade) => parseInt(grade.trim()));

      const gradeDetailsArray = await Promise.all(
        gradesList.map(async (grade) => {
          const response = await getActiveStudents(username, grade);
          if (response.details.length > 0) {
            const { student_count, assessment_count, average_score } =
              response.details[0];
            return {
              grade,
              activeStudents: student_count || 0,
              totalAssessments: assessment_count || 0,
              averageScore: average_score || 0,
            };
          }
          return {
            grade,
            activeStudents: 0,
            totalAssessments: 0,
            averageScore: 0,
          };
        })
      );

      const assessmentData = {
        gradeDetails: gradeDetailsArray,
        totalActiveStudents: gradeDetailsArray.reduce(
          (acc, details) => acc + details.activeStudents,
          0
        ),
        totalAssessments: gradeDetailsArray.reduce(
          (acc, details) => acc + details.totalAssessments,
          0
        ),
        averageScore: gradeDetailsArray.reduce(
          (acc, details, _, arr) => acc + details.averageScore / arr.length,
          0
        ),
      };

      sessionStorage.setItem("assessmentData", encryptData(assessmentData));
      setGradeDetails(gradeDetailsArray);
    } catch (error) {
      console.error("Error fetching assessment details:", error);
      setError("Failed to fetch assessment details");
    }
  };

  // Initialize data
  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const storedData = localStorage.getItem("userData");
        if (!storedData) {
          throw new Error("No user data found");
        }

        const decryptedData = decryptData(storedData);
        if (!decryptedData?.userData?.firstname) {
          throw new Error("Invalid user data");
        }

        const {
          userData: { firstname },
          username,
          classes: grade,
          usertype,
        } = decryptedData;
        const capitalizedFirstname =
          firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase();

        setUserData({
          firstname: capitalizedFirstname,
          username,
          grade,
          usertype,
        });

        if (usertype.toLowerCase() === "teacher") {
          await Promise.all([
            fetchStudents(username),
            fetchAssessmentDetails(username, grade),
          ]);
        }
      } catch (error) {
        console.error("Initialization error:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, []);

  // Carousel navigation handlers
  const handlePrevGrade = useCallback(() => {
    setCurrentGradeIndex((prev) =>
      prev === 0 ? gradeDetails.length - 1 : prev - 1
    );
  }, [gradeDetails.length]);

  const handleNextGrade = useCallback(() => {
    setCurrentGradeIndex((prev) =>
      prev === gradeDetails.length - 1 ? 0 : prev + 1
    );
  }, [gradeDetails.length]);

  // Auto-rotation effect
  useEffect(() => {
    let intervalId;

    if (isAutoRotating && !isPaused && gradeDetails.length > 1) {
      intervalId = setInterval(() => {
        handleNextGrade();
      }, CAROUSEL_INTERVAL);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAutoRotating, isPaused, handleNextGrade, gradeDetails.length]);
  const [isAddStudentModalOpen, setIsAddStudentModalOpen] = useState(false);

  const handleAddStudentSubmit = (formData) => {
    // Handle the form submission here
    console.log("Form submitted:", Object.fromEntries(formData));
    setIsAddStudentModalOpen(false);
  };

  // Update your Add New Student button onClick handler:
  const handleAddStudentClick = () => {
    setIsAddStudentModalOpen(true);
  };

  const handleLessonPlanClick = () => {
    navigate("/LessonPlanGenerator");
  };

  const handleActivityClick = () => {
    navigate("/ExpertActivityAssistant");
  };

  const handleAssessmentClick = () => {
    navigate("/GenerateAssessment");
  };
  // Add this to your state declarations
  const [isUpdateSyllabusModalOpen, setIsUpdateSyllabusModalOpen] =
    useState(false);
  // Update your settings button click handler
  const handleUpdateSyllabusClick = () => {
    setIsUpdateSyllabusModalOpen(true);
  };

  const getGreeting = () => {
    const hours = time.getHours();
    return `Good ${
      hours < 12 ? "Morning" : hours < 18 ? "Afternoon" : "Evening"
    }, ${userData.firstname}!`;
  };

  if (isLoading) {
    return <div className="loading">Loading dashboard...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }
  return (
    <div className="dashboard-container">
      <div className="overview-section">
        <h1 className="greeting">{getGreeting()}</h1>
        <button onClick={handleUpdateSyllabusClick} className="settings-button">
          <span>Update Syllabus</span>
        </button>
        <UpdateSyllabusModal
          isOpen={isUpdateSyllabusModalOpen}
          onClose={() => setIsUpdateSyllabusModalOpen(false)}
        />
        {gradeDetails.length > 0 && (
          <div className="carousel-container">
            <h2 className="carousel-title">Student Assessment Performance</h2>
            <div
              className="grade-carousel"
              onMouseEnter={() => setIsPaused(true)}
              onMouseLeave={() => setIsPaused(false)}
            >
              <button
                className="auto-rotate-control"
                onClick={() => setIsAutoRotating(!isAutoRotating)}
              >
                {isAutoRotating ? (
                  <>
                    <Pause className="w-4 h-4" /> <span>Pause</span>
                  </>
                ) : (
                  <>
                    <Play className="w-4 h-4" /> <span>Play</span>
                  </>
                )}
              </button>

              <div className="carousel-controls">
                <button
                  className="nav-button"
                  onClick={handlePrevGrade}
                  disabled={gradeDetails.length <= 1}
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <button
                  className="nav-button"
                  onClick={handleNextGrade}
                  disabled={gradeDetails.length <= 1}
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
              </div>

              {/* <GradeCard data={gradeDetails[currentGradeIndex]} /> */}
              <GradeKPI data={gradeDetails[currentGradeIndex]} />

              <div className="carousel-indicators">
                {gradeDetails.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentGradeIndex(index)}
                    className={`indicator ${
                      index === currentGradeIndex ? "active" : ""
                    }`}
                    aria-label={`Go to grade ${gradeDetails[index].grade}`}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="grid-container">
        <div className="add-student-section">
          <div className="flex items-center justify-between gap-4">
            <p className="text-gray-600 whitespace-nowrap">
              Add and manage new students to your classes
            </p>
            <button
              onClick={handleAddStudentClick}
              className="px-6 py-2.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center gap-2 whitespace-nowrap"
            >
              <span>Add New Student</span>
              <ChevronRight className="w-4 h-4" />
            </button>
            <AddStudentModal
              isOpen={isAddStudentModalOpen}
              onClose={() => setIsAddStudentModalOpen(false)}
              onSubmit={handleAddStudentSubmit}
            />
          </div>
        </div>
        <div className="add-student-section">
          <div className="flex items-center justify-between gap-4">
            <p className="text-gray-600 whitespace-nowrap">
              Add a LessonPlan for your Tomorrow classes
            </p>
            <button
              onClick={handleLessonPlanClick}
              className="px-6 py-2.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center gap-2 whitespace-nowrap"
            >
              <span>Generate Lesson Plan</span>
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className="add-student-section">
          <div className="flex items-center justify-between gap-4">
            <p className="text-gray-600 whitespace-nowrap">
              Plan An Activity for your Tomorrow classes
            </p>
            <button
              onClick={handleActivityClick}
              className="px-6 py-2.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center gap-2 whitespace-nowrap"
            >
              <span>Generate Activity</span>
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
        <div className="add-student-section">
          <div className="flex items-center justify-between gap-4">
            <p className="text-gray-600 whitespace-nowrap">
              Start An Assessment for your Students
            </p>
            <button
              onClick={handleAssessmentClick}
              className="px-6 py-2.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center gap-2 whitespace-nowrap"
            >
              <span>Generate Assessment</span>
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
      <DropdownFilters username={userData.username} />
      <div className="student-table-container">
        <div className="tables-stack">
          <div className="recent-students-section">
            <h2 className="section-title">Top Performers Students</h2>
            <table className="students-table">
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Subject</th>
                  <th>Assessment Score</th>
                  <th>Average Score</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>John Doe</td>
                  <td>Maths</td>
                  <td>85</td>
                  <td>78</td>
                  <td className="status">
                    <span className="status-badge needs-improvement">
                      needs improvement
                    </span>
                    <span className="status-badge good">good</span>
                  </td>
                  <td>
                    <button className="view-button">View</button>
                  </td>
                </tr>
                <tr>
                  <td>Jane Smith</td>
                  <td>Maths</td>
                  <td>92</td>
                  <td>88</td>
                  <td className="status">
                    <span className="status-badge excellent">excellent</span>
                    <span className="status-badge good">good</span>
                  </td>
                  <td>
                    <button className="view-button">View</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="recent-students-section">
            <h2 className="section-title">Students Need Assistance</h2>
            <table className="students-table">
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Subject</th>
                  <th>Assessment Score</th>
                  <th>Average Score</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Swaroop</td>
                  <td>Biology</td>
                  <td>85</td>
                  <td>78</td>
                  <td className="status">
                    <span className="status-badge needs-improvement">
                      needs improvement
                    </span>
                    <span className="status-badge good">good</span>
                  </td>
                  <td>
                    <button className="view-button">View</button>
                  </td>
                </tr>
                <tr>
                  <td>Jane Smith</td>
                  <td>Biology</td>
                  <td>92</td>
                  <td>88</td>
                  <td className="status">
                    <span className="status-badge excellent">excellent</span>
                    <span className="status-badge good">good</span>
                  </td>
                  <td>
                    <button className="view-button">View</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
