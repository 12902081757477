import React from "react";
import Header from "./Header";
// interface HeaderRouterProps {
//   userType: string;
// }

const HeaderRouter = ({ userType }) => {
  const getMenus = () => {
    console.log(userType);
    switch (userType) {
      case "teacher":
        return [
          {
            type: "text",
            text: "Dashboard",
            link: "/TeacherHome",
            // link: "/teacher/home",
          },
          {
            type: "dropdown",
            text: "Assistant",
            link: "",
            menus: [
              {
                text: "Lesson Planner",
                // link: "/teacher/lesson-planner",
                link: "/LessonPlan",
              },
              {
                text: "Activity Generator",
                // link: "/teacher/activity-generator",
                link: "/ExpertActivityAssistant",
              },
              {
                text: "Study Buddy",
                // link: "/teacher/study-buddy",
                link: "/Study",
              },
              {
                text: "Mentor mate",
                // link: "/teacher/mentor-mate",
                link: "/chatbot",
              },
            ],
          },
          {
            type: "dropdown",
            text: "Assessment",
            link: "",
            menus: [
              {
                text: "View Assessments",
                // link: "/teacher/show-assessments",
                link: "/TeacherViewAssessment",
              },
              {
                text: "Generate Assessment",
                // link: "/teacher/generate-assessment",
                link: "/GenerateAssesment",
              },
            ],
          },
          {
            type: "dropdown",
            text: "Account",
            icon: "userCircle",
            link: "",
            menus: [
              {
                text: "My Account",
                // link: "/teacher/show-assessments",
                link: "/account",
              },
              {
                text: "Logout",
                link: "/logout",
              },
            ],
          },
        ];
      case "student":
        return [
          {
            type: "text",
            text: "Dashboard",
            // link: "/student/home",
            link: "/StudentHome",
            class: "",
          },
          {
            // type: "text",
            type: "dropdown",
            text: "Assessments",
            link: "/student/assesment",
            class: "",
            menus: [
              {
                text: "Mock Test",
                // link: "/student/show-courses",
                link: "/",
              },
              {
                text: "Assessment",
                // link: "/student/generate-courses",
                link: "/ViewAssessment",
              },
              {
                text: "Reports",
                // link: "/student/generate-courses",
                link: "/view-Reports",
              },
            ],
          },
          {
            type: "text",
            text: "Study Buddy",
            // link: "/student/study-buddy",
            link: "/student-study",
            class: "",
          },
          {
            type: "text",
            text: "Learn Mate",
            // link: "/student/learn-mate",
            link: "/student-chatbot",
            class: "",
          },
          {
            type: "dropdown",
            text: "Course",
            link: "",
            class: "",
            menus: [
              {
                text: "View Course",
                // link: "/student/show-courses",
                link: "/shortCourse",
              },
              {
                text: "Generate Course",
                // link: "/student/generate-courses",
                link: "/GenerateCourse",
              },
            ],
          },
          {
            type: "dropdown",
            text: "Account",
            icon: "userCircle",
            link: "",
            menus: [
              {
                text: "My Account",
                // link: "/teacher/show-assessments",
                link: "/account",
              },
              {
                text: "Logout",
                link: "/logout",
              },
            ],
          },
        ];
      case "saas":
        return [
          {
            type: "text",
            text: "Dashboard",
            link: "/saas/home",
          },
          {
            type: "text",
            text: "Schedules",
            link: "/schedules",
          },
          {
            type: "text",
            text: "LessonPlan",
            link: "/lesson-plan",
          },
          {
            type: "text",
            text: "Documents",
            link: "/documents",
          },
          {
            type: "text",
            text: "My Account",
            link: "/my-account",
          },
          {
            type: "button_Link",
            text: "Logout",
            link: "/logout",
            class: "primary",
          },
        ];
      case "pending":
        return [
          {
            type: "text",
            text: "My Account",
            link: "/my-account",
          },
          {
            type: "button_Link",
            text: "Logout",
            link: "/logout",
            class: "primary",
          },
        ];
      default:
        return [
          {
            type: "button_Link",
            text: "Log In",
            link: "/login",
            class: "primary btn_dark_bg",
          },
          {
            type: "button_Link",
            text: "Sign Up",
            link: "/register",
            class: "",
          },
        ];
    }
  };

  return <Header menu={getMenus()} />;
};

export default HeaderRouter;
