import React, { useState, useEffect } from "react";
import { Upload } from "lucide-react";
import { getAllSchools, getAllBoards, addStudent } from "../api";
import countryCodes from "../countryCodes"; // Make sure this is correctly imported
import "./AddStudent.css";
import CryptoJS from "crypto-js";

const decryptData = (encryptedData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const StudentManagement = () => {
  const [activeTab, setActiveTab] = useState("singleStudent");
  const [name, setName] = useState("");
  const [grade, setGrade] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodes[0].display
  );
  const [newSchoolName, setNewSchoolName] = useState("");
  const [schoolAddress, setSchoolAddress] = useState("");

  const [boards, setBoards] = useState([]);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchBoards = async () => {
      const data = await getAllBoards();
      setBoards(data || []);
    };

    const fetchSchools = async () => {
      const data = await getAllSchools();
      setSchools(data || []);
    };

    fetchBoards();
    fetchSchools();
  }, []);

  const handleSubmit = async (event) => {
    const encryptedUserData = localStorage.getItem("userData");
    const userDetails = encryptedUserData ? decryptData(encryptedUserData) : {};

    event.preventDefault();
    const studentData = {
      name,
      grade,
      board: selectedBoard,
      school: selectedSchool,
      email,
      phone: `${selectedCountryCode} ${phone}`,
      ...(selectedSchool.startsWith("Other") && {
        newSchoolName,
        schoolAddress,
      }),
    };
    try {
      const response = await addStudent(studentData, userDetails.username);
      console.log("Student added:", response);
    } catch (error) {
      console.error("Error adding student:", error);
    }
  };

  return (
    <div className="container">
      <nav className="nav student-nav">
        <div className="nav-tabs">
          <button
            className={`nav-tab ${
              activeTab === "singleStudent" ? "active" : ""
            }`}
            onClick={() => setActiveTab("singleStudent")}
          >
            <span className="material-icons"></span>
            <span>Single Student</span>
          </button>
          <button
            className={`nav-tab ${activeTab === "bulkUpload" ? "active" : ""}`}
            onClick={() => setActiveTab("bulkUpload")}
          >
            <span className="material-icons"></span>
            <span>Bulk Upload</span>
          </button>
        </div>
      </nav>
      {activeTab === "singleStudent" && (
        <div className="form-container">
          <h2 className="form-title">Add Student</h2>
          <form className="student-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name of the student"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Grade"
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              required
            />
            <select
              value={selectedBoard}
              onChange={(e) => setSelectedBoard(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Board
              </option>
              {boards.map((board, index) => (
                <option key={index} value={board.board}>
                  {board.board}
                </option>
              ))}
            </select>
            <select
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
              required
            >
              <option value="" disabled>
                Select School
              </option>
              {schools.map((school, index) => (
                <option
                  key={index}
                  value={`${school.school_name}, ${school.location || "N/A"}`}
                >{`${school.school_name}, ${school.location || "N/A"}`}</option>
              ))}
            </select>
            {selectedSchool.startsWith("Other") && (
              <>
                <input
                  type="text"
                  placeholder="New School Name"
                  value={newSchoolName}
                  onChange={(e) => setNewSchoolName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="School Address"
                  value={schoolAddress}
                  onChange={(e) => setSchoolAddress(e.target.value)}
                  required
                />
              </>
            )}
            <input
              type="email"
              placeholder="Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <select
                value={selectedCountryCode}
                onChange={(e) => setSelectedCountryCode(e.target.value)}
                required
              >
                {countryCodes.map((code, index) => (
                  <option key={index} value={code.display}>
                    {code.code} ({code.display})
                  </option>
                ))}
              </select>
              <input
                type="tel"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) =>
                  setPhone(e.target.value.replace(/[^0-9]/g, ""))
                }
                required
              />
            </div>
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </form>
        </div>
      )}
      {activeTab === "bulkUpload" && (
        <div className="form-container">
          <h2 className="form-title">Bulk Upload Students</h2>
          <div className="file-upload-area">
            <Upload size={48} className="upload-icon" />
            <p>Drag and drop your file here, or click to select a file</p>
            <input
              type="file"
              accept=".csv,.xlsx,.xls"
              className="file-input"
              id="fileInput"
              onChange={(e) => console.log("File selected:", e.target.files)}
              required
            />
            <label htmlFor="fileInput" className="file-label">
              Select File
            </label>
          </div>
          <div className="file-instructions">
            <h3>File Format Instructions:</h3>
            <ul>
              <li>Use CSV, XLSX, or XLS file formats</li>
              <li>
                Include columns: Name, Grade, Board, School Name, Email, Phone
              </li>
              <li>Ensure all required fields are filled</li>
              <li>Use a single sheet for XLSX/XLS files</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentManagement;
