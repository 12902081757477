// src/assesment_api.js

const API_URL = "https://aiala.troshi.in/api";

// API functions for data fetching
export const fetchGrades = async () => {
  try {
    const response = await fetch(`${API_URL}/assesment/getGrades`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    if (!response.ok) throw new Error("Failed to fetch grades");
    return await response.json();
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

export const fetchBoards = async (grade) => {
  try {
    const response = await fetch(`${API_URL}/assesment/getBoards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ grade }),
    });
    if (!response.ok) throw new Error("Failed to fetch boards");
    return await response.json();
  } catch (error) {
    console.error("Error fetching boards:", error);
    throw error;
  }
};

export const fetchSubjects = async (grade, board) => {
  try {
    const response = await fetch(`${API_URL}/assesment/getSubjects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ grade, board }),
    });
    if (!response.ok) throw new Error("Failed to fetch subjects");
    return await response.json();
  } catch (error) {
    console.error("Error fetching subjects:", error);
    throw error;
  }
};

export const fetchChapters = async (grade, board, subject) => {
  try {
    const response = await fetch(`${API_URL}/assesment/getChapters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ grade, board, subject }),
    });
    if (!response.ok) throw new Error("Failed to fetch chapters");
    return await response.json();
  } catch (error) {
    console.error("Error fetching chapters:", error);
    throw error;
  }
};
export const fetchSubTopic = async (grade, board, subject, chapternumber) => {
  try {
    const response = await fetch(`${API_URL}/assesment/getSubtopic`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ grade, board, subject, chapternumber }),
    });
    if (!response.ok) throw new Error("Failed to fetch grades");
    return await response.json();
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

export const getPendingAssessments = async (username) => {
  try {
    const response = await fetch(`${API_URL}/assesment/getPendingAssessments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ username }), // Include username in the body of the POST request
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch grades: ${response.statusText}`);
    }
    const text = await response.text(); // Use .text() to handle non-JSON responses gracefully
    return text ? JSON.parse(text) : {}; // Only parse if text is not empty
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};

export const CompleteSubTopic = async (
  grade,
  board,
  subject,
  chapternumber,
  subtopic_name,
  remarks
) => {
  try {
    const response = await fetch(`${API_URL}/assesment/CompleteSubTopic`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        grade,
        board,
        subject,
        chapternumber,
        subtopic_name,
        remarks,
      }),
    });
    if (!response.ok) throw new Error("Failed to fetch grades");
    return await response.json();
  } catch (error) {
    console.error("Error fetching grades:", error);
    throw error;
  }
};
